import { connect } from 'react-redux';
import {
  QR_REDIRECT_DATA,
  onFetchQrRedirectById,
  onUpdateField,
  onAddQrRedirect,
  onUpdateQrRedirect,
  resetQrRedirectState,
} from '../../redux/state';
import AddQrRedirect from './AddQrRedirect';

export const mapStateToProps = (state) => {
  const {
    id,
    redirectUrl,
    filePath,
    fileName,
    shouldRedirect,
  } = state[QR_REDIRECT_DATA];

  return {
    id,
    redirectUrl,
    filePath,
    fileName,
    shouldRedirect,
    qrRedirects: state.qrRedirects,
  };
};

export const mapDispatchToProps = {
  onFetchQrRedirectById,
  onUpdateField,
  onAddQrRedirect,
  onUpdateQrRedirect,
  resetQrRedirectState,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQrRedirect);
