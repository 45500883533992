import { Button, makeStyles, Input } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { QR_REDIRECT_DATA } from '../../redux/state';
import {
  buttonRed,
  buttonRedHover,
  inactiveGray,
  neuBlue,
  successGreen,
  white,
} from '../../colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '80%',
    margin: 'auto',
  },
  titleContainer: {
    marginTop: '75px',
  },
  titleText: {
    color: `${neuBlue}`,
    fontFamily: 'QuicksandMedium',
    textAlign: 'center',
  },
  titleLine: {
    width: '50px',
    height: '3px',
    background: `${neuBlue}`,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
    borderRadius: '2px',
  },
  headerRow: {
    marginTop: '70px',
    marginBottom: '30px',
  },
  headerRowTextContainer: {
    display: 'inline-block',
  },
  entityRowTitle: {
    fontFamily: 'QuicksandBold',
    wordBreak: 'break-word',
  },
  radioContainer: {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    '& input': {
      width: '15px',
      height: '15px',
    },
  },
  radioText: {
    fontFamily: 'QuicksandMedium',
    fontSize: '14px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  addNewJobInput: {
    width: '100%',
    padding: '10px',
    border: `1px solid ${inactiveGray}`,
    borderRadius: '5px',
    marginBottom: '10px',
    fontSize: '14px',
    fontFamily: 'QuicksandMedium',
    outline: 'none',
  },
  editor: {
    border: `1px solid ${inactiveGray}`,
    borderRadius: '5px',
  },
  editorToolbar: {
    borderRadius: '5px !important',
    marginBottom: '0 !important',
    borderBottom: `1px solid ${inactiveGray} !important`,
  },

  editorText: {
    background: `${white}`,
    padding: '10px',
    borderRadius: '5px',
    fontFamily: 'QuicksandRegular',
    fontSize: '14px',
  },

  saveButton: {
    marginTop: '50px',
    marginBottom: '100px',
    width: '100px',
    fontSize: '14px',
    fontFamily: 'QuicksandMedium',
    color: `${white} !important`,
    backgroundColor: `${successGreen} !important`,
    borderColor: `${successGreen} !important`,
    textTransform: 'none !important',
  },

  buttonContainer: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',

    '& .Mui-disabled': {
      backgroundColor: 'gray !important',
    },
  },
  buttonCancel: {
    marginTop: '50px',
    marginBottom: '100px',
    width: '100px',
    fontSize: '14px',
    fontFamily: 'QuicksandMedium',
    color: `${white} !important`,
    backgroundColor: `${buttonRed}`,
    textTransform: 'none !important',
    '&:hover': {
      backgroundColor: `${buttonRedHover}`,
    },
    marginLeft: '50px',
  },
  descriptionEditor: {
    '& .rdw-fontsize-wrapper': {
      display: 'none',
    },
    '& .rdw-fontfamily-wrapper': {
      display: 'none',
    },
  },
  textFieldInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    color: buttonRed,
    marginBottom: '50px',
    marginLeft: '10px',
  },
  redirectUrlExists: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
    color: buttonRed,
  },
  shortDescription: {
    marginBottom: '0px !important',
  },
  fileInput: {
    display: 'none',
  },
  fileInputLabel: {
    width: '100%',
    padding: '10px',
    border: `1px solid ${inactiveGray}`,
    borderRadius: '5px',
    marginBottom: '40px',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: 'QuicksandMedium',
    cursor: 'pointer',
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    backgroundColor: 'white',
  },
}));

const AddQrRedirect = ({
  id,
  shouldRedirect,
  redirectUrl,
  fileName,
  onFetchQrRedirectById,
  onUpdateField,
  onUpdateQrRedirect,
  onAddQrRedirect,
  resetQrRedirectState,
  qrRedirects,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState(null);

  const redirectUrlExists = qrRedirects.some(
    (qrRedirect) => qrRedirect.redirectUrl === redirectUrl && qrRedirect.id !== id,
  );

  useEffect(() => {
    if (window.location.pathname !== '/add-new-qr-redirect') {
      const location = window.location.pathname;
      const qrRedirectId = location.substring(location.lastIndexOf('/') + 1);
      onFetchQrRedirectById(qrRedirectId);
    }
  }, []);

  useEffect(() => () => {
    resetQrRedirectState();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (redirectUrlExists) {
      return null;
    }

    let filePath = '';
    if (file) {
      // Get pre-signed URL

      const urlEncodedFileName = encodeURIComponent(file.name);

      const response = await axios.post(`${process.env.REACT_APP_URL}/create-redirect-file-upload-url`, {
        fileName: `qr-files/${file.name}`,
        type: file.type,
      }, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
      });

      // Upload file to S3
      await axios.put(response.data.url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      filePath = `/qr-files/${urlEncodedFileName}`;
    }

    const qrRedirect = {
      redirectUrl: String(redirectUrl).trim(),
      fileName,
      filePath,
      id,
    };

    if (window.location.pathname !== '/add-new-qr-redirect') {
      return onUpdateQrRedirect(qrRedirect);
    }
    return onAddQrRedirect(qrRedirect);
  };

  const handleRedirectUrlChange = (e) => {
    const { value } = e.target;

    // only letters, numbers, and -
    // remove everything else
    const sanitizedValue = value.replace(/[^a-zA-Z0-9-]/g, '');

    onUpdateField(QR_REDIRECT_DATA, 'redirectUrl', sanitizedValue);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      onUpdateField(QR_REDIRECT_DATA, 'fileName', selectedFile.name);
    }
  };

  const validateFields = () => {
    const fields = [redirectUrl, fileName];
    return fields.every((field) => field.length > 0);
  };

  if (shouldRedirect) {
    return (<Redirect to="/qr-redirects" />);
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <h1 className={classes.titleText}>
          {window.location.pathname !== '/add-new-qr-redirect' ? 'Edit QR Redirect' : 'Add New QR Redirect'}
        </h1>
        <div className={classes.titleLine} />
      </div>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className={classes.addNewJobInput}
          placeholder="Enter Redirect URL (Required)*"
          onChange={handleRedirectUrlChange}
          value={redirectUrl}
          disabled={String(id).length > 0}
        />
        {redirectUrlExists && (
          <p className={classes.redirectUrlExists}>Redirect URL already exists</p>
        )}
        <Input
          type="file"
          id="file-input"
          className={classes.fileInput}
          onChange={handleFileChange}
        />
        <label htmlFor="file-input" className={classes.fileInputLabel}>
          {fileName || 'Select a file (Required)'}
        </label>

        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            className={classes.saveButton}
            disabled={!validateFields() || redirectUrlExists}
          >
            Save
          </Button>

          <Button
            className={classes.buttonCancel}
            component={Link}
            to="/qr-redirects"
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddQrRedirect;
