import React from 'react';
import {
  AppBar, makeStyles, Toolbar,
} from '@material-ui/core';

import { NavLink, Link, useLocation } from 'react-router-dom';
import { neuBlue } from '../../colors';

const useStyles = makeStyles(() => ({
  logo: {
    backgroundImage: 'url("../images/NeusoftLogo.svg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '120px',
    height: '45px',
    marginRight: '20px',
    marginTop: '10px',
  },

  tab: {
    fontFamily: 'QuicksandBold',
    color: `${neuBlue} !important`,
    fontSize: '110%',
    cursor: 'pointer',
    textAlign: 'center',
    marginRight: '2em',
    '&:hover': {
      color: 'rgba(0, 0, 0, 1) !important',
      cursor: 'pointer',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  selectedTab: {
    color: 'rgba(0, 0, 0, 1) !important',
  },

  headerSection: {
    display: 'flex',
  },

  underLine: {
    width: '20px',
    height: '2px',
    background: `${neuBlue} !important`,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    borderRadius: '1px',
  },

  visibilityHidden: {
    visibility: 'hidden',
  },

  navlink: {
    fontFamily: 'QuicksandBold',
    color: 'rgba(0, 0, 0, 0.7) !important',
    cursor: 'pointer',
    fontSize: '95%',
    '&:hover': {
      color: 'rgba(0, 0, 0, 1) !important',
      cursor: 'pointer',
    },
    marginLeft: '15px',
  },

  container: {
    display: 'flex',
    padding: '0 10%',
    height: '70px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const Header = ({
  userName,
  isQrUser,
  onLogout,
}) => {
  const classes = useStyles();

  const location = useLocation();

  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <div className={classes.container}>
          <div className={classes.headerSection}>
            <Link className={classes.logo} to={isQrUser ? '/qr-redirects' : '/jobs'} />
            {!isQrUser ? (
              <NavLink
                activeClassName={location.pathname === '/jobs' ? classes.selectedTab : ''}
                to="/jobs"
                style={{ textDecoration: 'none' }}
                className={classes.tab}
              >
                Jobs
                <div className={[classes.underLine, location.pathname === '/jobs' ? '' : classes.visibilityHidden].join(' ')} />
              </NavLink>
            )
              : (
                <NavLink
                  activeClassName={location.pathname === '/qr-redirects' ? classes.selectedTab : ''}
                  to="/qr-redirects"
                  style={{ textDecoration: 'none' }}
                  className={classes.tab}
                >
                  QR Redirects
                  <div className={[classes.underLine, location.pathname === '/qr-redirects' ? '' : classes.visibilityHidden].join(' ')} />
                </NavLink>
              )}
          </div>
          <div className={classes.headerSection}>
            <span className="navbar-text">
              <span id="nav-link-custom-username" className={classes.navlink}>{userName}</span>
            </span>
            <span className="navbar-text">
              {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
              <span
                className={classes.navlink}
                role="button"
                onKeyPress={onLogout}
                onClick={onLogout}
              >
                Sign Out
              </span>
            </span>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
