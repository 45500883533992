export const TEST = 'TEST';
export const ON_LOGIN = 'ON_LOGIN';
export const ON_LOGOUT = 'ON_LOGOUT';
export const ON_UPDATE_FIELD = 'ON_UPDATE_FIELD';
export const ON_FETCH_JOBS = 'ON_FETCH_JOBS';
export const ON_FETCH_QR_REDIRECTS = 'ON_FETCH_QR_REDIRECTS';
export const ON_FETCH_JOB_BY_ID = 'ON_FETCH_JOB_BY_ID';
export const ON_UPDATE_JOB_STATUS = 'ON_UPDATE_JOB_STATUS';
export const ON_DELETE_JOB = 'ON_DELETE_JOB';
export const ON_CHANGE_PASSWORD = 'ON_CHANGE_PASSWORD';
export const SET_JOBS = 'SET_JOBS';
export const ON_ADD_JOB = 'ON_ADD_JOB';
export const ON_UPDATE_JOB = 'ON_UPDATE_JOB';
export const SET_JOB_DATA = 'SET_JOB_DATA';
export const RESET_JOB_STATE = 'RESET_JOB_STATE';
export const SET_QR_REDIRECTS = 'SET_QR_REDIRECTS';
export const AUTH = 'AUTH';
export const JOB_DATA = 'JOB_DATA';
export const QR_REDIRECT_DATA = 'QR_REDIRECT_DATA';
export const ON_FETCH_QR_REDIRECT_BY_ID = 'ON_FETCH_QR_REDIRECT_BY_ID';
export const SET_QR_REDIRECT_DATA = 'SET_QR_REDIRECT_DATA';
export const ON_ADD_QR_REDIRECT = 'ON_ADD_QR_REDIRECT';
export const ON_UPDATE_QR_REDIRECT = 'ON_UPDATE_QR_REDIRECT';
export const ON_DELETE_QR_REDIRECT = 'ON_DELETE_QR_REDIRECT';
export const RESET_QR_REDIRECT_STATE = 'RESET_QR_REDIRECT_STATE';
// triggered from redux-sagas
export const testAction = (param) => ({
  type: TEST,
  param,
});

export const onLogin = (email, password) => ({
  type: ON_LOGIN,
  email,
  password,
});

export const onLogout = () => ({
  type: ON_LOGOUT,
});

export const onPasswordChange = (user, password) => ({
  type: ON_CHANGE_PASSWORD,
  user,
  password,
});

export const onUpdateField = (object, key, value) => ({
  type: ON_UPDATE_FIELD,
  object,
  key,
  value,
});

export const onUpdateJobStatus = (id, newStatus) => ({
  type: ON_UPDATE_JOB_STATUS,
  id,
  newStatus,
});

export const onDeleteJob = (id) => ({
  type: ON_DELETE_JOB,
  id,
});

export const onAddJob = (job) => ({
  type: ON_ADD_JOB,
  job,
});

export const onAddQrRedirect = (qrRedirect) => ({
  type: ON_ADD_QR_REDIRECT,
  qrRedirect,
});

export const onUpdateJob = (job) => ({
  type: ON_UPDATE_JOB,
  job,
});

export const onUpdateQrRedirect = (qrRedirect) => ({
  type: ON_UPDATE_QR_REDIRECT,
  qrRedirect,
});

export const onFetchJobs = () => ({
  type: ON_FETCH_JOBS,
});

export const onFetchQrRedirects = () => ({
  type: ON_FETCH_QR_REDIRECTS,
});

export const onFetchJobById = (id) => ({
  type: ON_FETCH_JOB_BY_ID,
  id,
});

export const setJobs = (jobs) => ({
  type: SET_JOBS,
  jobs,
});

export const setJobData = (jobData) => ({
  type: SET_JOB_DATA,
  jobData,
});

export const resetJobState = () => ({
  type: RESET_JOB_STATE,
});

export const resetQrRedirectState = () => ({
  type: RESET_QR_REDIRECT_STATE,
});

export const setQrRedirects = (qrRedirects) => ({
  type: SET_QR_REDIRECTS,
  qrRedirects,
});

export const setQrRedirectData = (qrRedirectData) => ({
  type: SET_QR_REDIRECT_DATA,
  qrRedirectData,
});

export const onFetchQrRedirectById = (id) => ({
  type: ON_FETCH_QR_REDIRECT_BY_ID,
  id,
});

export const onDeleteQrRedirect = (id) => ({
  type: ON_DELETE_QR_REDIRECT,
  id,
});

export const defaultJobDataState = {
  shouldRedirect: false,
  isEnabled: true,
  title: '',
  descriptionTitle: '',
  descriptionText: '',
};

export const defaultQrRedirectDataState = {
  id: '',
  redirectUrl: '',
  filePath: '',
  fileName: '',
};

export const defaultState = {
  [AUTH]: {
    loginEmail: '',
    loginPassword: '',
    user: null,
    resetPassword: '',
    resetConfirmPassword: '',
  },
  jobs: [],
  [JOB_DATA]: {
    ...defaultJobDataState,
  },
  qrRedirects: [],
  [QR_REDIRECT_DATA]: {
    ...defaultQrRedirectDataState,
  },
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_JOBS:
      return {
        ...state,
        jobs: action.jobs,
      };
    case SET_QR_REDIRECTS:
      return {
        ...state,
        qrRedirects: action.qrRedirects,
      };
    case SET_JOB_DATA:
      return {
        ...state,
        [JOB_DATA]: action.jobData,
      };
    case SET_QR_REDIRECT_DATA:
      return {
        ...state,
        [QR_REDIRECT_DATA]: action.qrRedirectData,
      };
    case RESET_JOB_STATE:
      return {
        ...state,
        [JOB_DATA]: { ...defaultJobDataState },
      };
    case RESET_QR_REDIRECT_STATE:
      return {
        ...state,
        [QR_REDIRECT_DATA]: { ...defaultQrRedirectDataState },
      };
    case ON_UPDATE_FIELD:
      return {
        ...state,
        [action.object]: {
          ...state[action.object],
          [action.key]: action.value,
        },
      };
    default:
      return state;
  }
}

export default reducer;
