import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AUTH } from '../../redux/state';
import ChangePassword from '../../components/ChangePasswordForm';
import {
  dividerGray,
  errorRed,
  inputDarkGray,
  neuBlue,
  white,
} from '../../colors';

const useStyles = makeStyles(() => ({
  backgroundImage: {
    backgroundImage: 'url("../images/login4.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '50vh',
    position: 'absolute',
  },
  formContainer: {
    position: 'relative',
    width: '550px',
    height: '80vh',
    background: `${white}`,
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)',
    top: '10vh ',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '1.3',
  },
  title: {
    color: `${neuBlue}`,
    fontFamily: 'QuicksandMedium',
    textAlign: 'center',
  },
  titleLine: {
    width: '70px',
    height: '4px',
    background: `${neuBlue}`,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '25px',
    borderRadius: '2px',
  },
  form: {
    width: '60%',
    margin: '0 auto',
    flex: 2,
  },
  loginLabel: {
    fontFamily: 'QuicksandRegular',
    color: `${inputDarkGray}`,
    fontSize: '14px',
    margin: '0',
    marginLeft: '10px',
  },
  loginInput: {
    fontFamily: 'QuicksandRegular',
    fontSize: '14px',
    paddingLeft: '10px',
    paddingBottom: '5px',
    width: '100%',
    border: '0',
    borderBottom: `solid 2px ${dividerGray}`,
    color: `${inputDarkGray}`,
    marginTop: '5px',
    outline: 'none',
  },
  passwordInput: {
    marginTop: '30px',
  },
  loginButton: {
    fontFamily: 'QuicksandRegular',
    fontSize: '16px',
    border: 'none',
    borderRadius: '25px',
    color: `${white}`,
    padding: '12px',
    textAlign: 'center',
    width: '180px',
    marginTop: '75px',
    cursor: 'pointer',
    backgroundColor: `${neuBlue}`,
  },
  loginErrorContainer: {
    position: 'relative',
  },
  loginError: {
    color: `${errorRed}`,
    fontSize: '14px',
    fontFamily: 'QuicksandRegular',
    position: 'absolute',
    top: '15px',
    marginLeft: '10px',
    display: 'block',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Login = ({
  email,
  password,
  invalidCredentials,
  onLogin,
  onUpdateField,
  isAuthenticated,
  isPasswordChangeRequired,
  wrongCredentials,
}) => {
  const classes = useStyles();

  const handleEmailChange = (e) => {
    onUpdateField(AUTH, 'loginEmail', e.target.value);
    onUpdateField(AUTH, 'wrongCredentials', false);
  };

  const handlePassChange = (e) => {
    onUpdateField(AUTH, 'loginPassword', e.target.value);
    onUpdateField(AUTH, 'wrongCredentials', false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to={{ pathname: '/' }} />;
  }
  if (isPasswordChangeRequired) {
    return <ChangePassword />;
  }

  return (
    <div>
      <div className={classes.backgroundImage} />
      <div className={classes.formContainer}>
        <div className={classes.titleContainer}>
          <h1 className={classes.title}>Job Manager</h1>
          <div className={classes.titleLine} />
        </div>
        <form className={classes.form} autoComplete="on" onSubmit={handleSubmit}>
          <div>
            <label className={classes.loginLabel}>
              Email address
              <input
                type="email"
                name="email"
                autoComplete="on"
                className={classes.loginInput}
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={handleEmailChange}
              />
            </label>
          </div>
          <div className={classes.passwordInput}>
            <label className={classes.loginLabel}>
              Password
              <input
                name="password"
                autoComplete="on"
                type="password"
                className={classes.loginInput}
                onChange={handlePassChange}
              />
            </label>
          </div>
          {invalidCredentials ? (
            <div className={classes.loginErrorContainer}>
              <span className={classes.loginError}>Invalid credentials!</span>
            </div>
          ) : (<></>)}
          {wrongCredentials ? (
            <div className={classes.loginErrorContainer}>
              <span className={classes.loginError}>Username or password is wrong</span>
            </div>
          ) : (<></>)}

          <div className={classes.buttonContainer}>
            <button type="submit" className={classes.loginButton}>LOG IN</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
