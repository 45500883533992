import React, { useEffect } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { neuBlue, successGreen, white } from '../../colors';
import Redirect from '../../components/Redirect/Redirect';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    margin: 'auto',
  },
  titleContainer: {
    marginTop: '75px',
    maxWidth: '1140px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: `${neuBlue}`,
    fontFamily: 'QuicksandRegular',
    textAlign: 'center',
    margin: '0 auto',
  },
  titleLine: {
    width: '50px',
    height: '3px',
    background: `${neuBlue}`,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
    borderRadius: '2px',
  },
  jobContainer: {
  },
  saveButton: {
    marginTop: '50px',
    fontSize: '14px',
    fontFamily: 'QuicksandMedium',
    color: `${white} !important`,
    backgroundColor: `${successGreen} !important`,
    borderColor: `${successGreen} !important`,
    textTransform: 'none !important',
    minWidth: '124px',
  },
  placeholder: {
    minWidth: '124px',
  },

}));

const QrRedirects = ({
  qrRedirects,
  onFetchQrRedirects,
  onDeleteQrRedirect,
}) => {
  const classes = useStyles();

  useEffect(() => {
    onFetchQrRedirects();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className="col-sm">
          <Button
            className={classes.saveButton}
            component={Link}
            to="/add-new-qr-redirect"
          >
            Add New QR Redirect
          </Button>
        </div>
        <div className="homepage-title-wrapper-jobs col-sm">
          <h1 className={classes.title}>QR Redirects</h1>
          <div className={classes.titleLine} />
        </div>
        <div className={classes.placeholder} />
      </div>
      <div className={classes.jobContainer}>
        {qrRedirects.map((qrRedirect) => (
          <Redirect
            key={qrRedirect.id}
            redirect={qrRedirect}
            onDeleteRedirect={onDeleteQrRedirect}
          />
        ))}
      </div>
    </div>
  );
};

export default QrRedirects;
