export default {
  apiGateway: {
    // remove the host to use the same origin as the webapp
    URL: process.env.REACT_APP_URL,
    REGION: 'eu-central-1',
  },
  cognito: {
    REGION: 'eu-central-1',
    // USER_POOL_ID and APP_CLIENT_ID can be found in the console (after deployment is run)
    // Or in AWS console
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,

    // DOMAIN, SCOPE and REDIRECT urls are configured in the AWS console/Cognito
    // This values are used to integrate with the "federatedSignIn"
    // By calling Auth.federatedSignIn, user will be redirected to AWS built in form pages
    // from where login and change password can be done.
    // DOMAIN: 'nii21w5p3l.auth.eu-central-1.amazoncognito.com',
    // SCOPE: ['email', 'openid'],
    // After proceeding with the AWS built in form pages,
    // the user will be redirected to one of this URLs
    // REDIRECT_SIGN_IN: 'https://jobmanager.domain/',
    // REDIRECT_SIGN_OUT: 'https://jobmanager.domain/',
    // RESPONSE_TYPE: 'code',
  },
  storage: {
    bucket: process.env.REACT_APP_STORAGE_BUCKET,
    region: process.env.REACT_APP_STORAGE_REGION,
  },
};
