import { connect } from 'react-redux';
import { onFetchQrRedirects, onDeleteQrRedirect } from '../../redux/state';
import QrRedirects from './QrRedirects';

export const mapStateToProps = (state) => {
  const {
    qrRedirects,
  } = state;

  return {
    qrRedirects,
  };
};

export const mapDispatchToProps = {
  onFetchQrRedirects,
  onDeleteQrRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(QrRedirects);
