import React, { useRef } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import QRCode from 'react-qr-code';
import {
  buttonRed, buttonRedHover, lightGray, neuBlue, white,
} from '../../colors';

const useStyles = makeStyles(() => ({
  redirectRow: {
    marginTop: '50px',
  },
  titleContainer: {
    display: 'inline-block',
  },
  rowEntryTitle: {
    fontFamily: 'QuicksandBold',
    wordBreak: 'break-word',
  },
  rowTitleLine: {
    width: '50px',
    height: '3px',
    background: `${neuBlue}`,
    borderRadius: '1.5px',
  },
  redirectRowText: {
    marginTop: '20px',
    marginBottom: '30px',
    fontSize: '14px',
    fontFamily: 'QuicksandMedium',
    wordBreak: 'break-word',
  },
  buttonContainer: {
    marginBottom: '50px',
  },
  button: {
    fontFamily: 'QuicksandMedium',
    fontSize: '14px',
    width: '80px',
    marginRight: '25px',
    textTransform: 'none !important',
  },
  buttonError: {
    fontFamily: 'QuicksandMedium',
    fontSize: '14px',
    width: '80px',
    marginRight: '25px',
    backgroundColor: `${buttonRed}`,
    color: `${white} !important`,
    textTransform: 'none !important',
    '&:hover': {
      backgroundColor: `${buttonRedHover}`,
    },
  },
  line: {
    marginLeft: '-50px',
    marginRight: '-50px',
    color: `${lightGray}`,
  },
  downloadButton: {
    marginTop: '10px',
    fontFamily: 'QuicksandMedium',
    fontSize: '14px',
    textTransform: 'none !important',
  },
}));

const Redirect = ({
  redirect,
  onDeleteRedirect,
}) => {
  const classes = useStyles();
  const qrRef = useRef();

  const deleteRedirect = () => {
    confirmAlert({
      title: 'Are you sure you want to delete this redirect?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => onDeleteRedirect(redirect.id),
        },
        {
          label: 'No',
          onClick: () => console.log('Redirect NOT deleted', redirect.id),
        },
      ],
    });
  };

  const downloadQRCode = () => {
    const svg = qrRef.current.querySelector('svg');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `${redirect.fileName}_QR.png`;
      downloadLink.href = pngFile;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className={classes.redirectRow} key={redirect.id}>
          <div className={classes.titleContainer}>
            <p className={classes.rowEntryTitle}>{redirect.fileName}</p>
            <div className={classes.rowTitleLine} />
          </div>

          <div className={classes.redirectRowText}>
            <p>
              Redirect URL:
              {'  '}
              {redirect.redirectUrl}
            </p>
            <p>
              File Path:
              {redirect.filePath}
            </p>
          </div>

          <div className={classes.buttonContainer}>
            <Link to={`edit-qr-redirect/${redirect.id}`} style={{ textDecoration: 'none' }}>
              <Button variant="contained" className={classes.button} type="button">Edit</Button>
            </Link>
            <Button className={classes.buttonError} variant="contained" type="button" onClick={deleteRedirect}>Delete</Button>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div ref={qrRef}>
            <QRCode
              size={1000}
              style={{ height: 'auto', maxWidth: '200px', width: '100%' }}
              value={`${process.env.REACT_APP_SITE_URL}/redirect/${redirect.redirectUrl}`}
              viewBox="0 0 256 256"
            />
          </div>
          <Button
            variant="contained"
            className={classes.downloadButton}
            onClick={downloadQRCode}
          >
            Download QR
          </Button>
        </div>
      </div>
      <hr className={classes.line} />
    </div>
  );
};

export default Redirect;
